@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "DM Sans";
  font-style: normal;

  src: url("./fonts/DMSans-Regular.woff") format("woff");
}
.selectedClass fieldset {
  border-color: #3ac4f2 !important;
  border-width: 2px !important;
}
.fixedTag {
  background: #3AC4F2 !important;
  padding: 3px 10px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 0.24px !important;
  display: inline-flex !important;
  margin: 2px 2px 2px 0 !important;
}
.historyContent svg {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
}
.checklist {
  position: absolute;
  bottom: 0;
  left: 0;
}
.btnSuccess {
  background: #EDFCF2 !important;
  height: 40px !important;
  padding: 0 14px !important;
  border-radius: 12px !important;
  margin-top: 14px !important;
  color: #00B158 !important;
  font-family: 'poppins' !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.btnSuccess img {
  margin-right: 8px !important;
}

.btnAlert {
  background: #FEF3F2 !important;
  height: 40px !important;
  padding: 0 14px !important;
  border-radius: 12px !important;
  margin-top: 14px !important;
  color: #F04438 !important;
  font-family: 'poppins' !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.btnAlert img {
  margin-right: 8px !important;
}
.checklist ul {
  padding: 0 !important;
}
.checklist ul li {
  list-style: none;
}
.adsCard.active {
  border: 3px solid #3AC4F2;
  border-radius: 8px;
}
p.historyContent {
  display: flex
;
  align-items: center;
  font-family: 'Poppins';
  font-size: 14px !important;
}
.performanceTag {
  background: #36b37e !important;
  padding: 3px 10px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 0.24px !important;
  display: inline-flex !important;
  margin: 2px 2px 2px 0 !important;
}
.noData {
  text-align: center;
  margin: 68px;
}
.datePickers input::placeholder {
  font-family: 'Poppins' !important;
  opacity:  1 !important;
  color: #22294380 !important;
  font-weight: 300 !important;
}
.datePickers input {
  font-family: 'Poppins' !important;
  color: #222943CC !important;
  font-weight: 500 !important;
  font-size: 16px !important;

}
.addyearBtn {
  width: 138px;
  height: 48px;
  background: #E1F6FD !important;
  border: 0 !important;
  padding: 0 !important;
  color: #3AC4F2 !important;
  font-size: 16px !important;
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
}
.addyearBtn img {
  position: relative;
  bottom: 1px;
  right: 2px;
}
.datePickers .MuiStack-root {
  padding: 0 !important;
}
.datePickers .MuiFormControl-root {
  width: 100% !important;
}
.datePickers .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root {
  height: 50px !important;
  overflow: hidden !important;
  background-color: #F4F8FB !important;
}
.datePickers fieldset {
  border-color: #D8DAE1B2 !important;
}
.deleteButton {
  width: 160px;
  border-radius: 5px !important;
  height: 40px !important;
  background: #FFEAEA !important;
  border: 0 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #F23A3D !important;
  font-family: 'Poppins' !important;
}
.deleteButton img {
  width: 16px !important;
    height: 18px !important;
    object-fit: contain;
    position: relative;
    right: 2px;
    bottom: 1px !important;
}
.mainBoxed {
  border-bottom: 1px solid #DADADA;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
body .mainBoxed label {
  font-size: 16px !important;
  padding-bottom: 0 !important;
  color: #222943 !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  font-family: 'Poppins' !important;
}
.mainBoxed input {
  color: #222943 !important;
}
.mainBoxed input::placeholder {
  color: #222943 !important;
}
.invoiceTable tr td {
  font-size: 18px !important;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  padding: 18px 15px !important;
  border: 0 !important;
}
.addInvoice {
  color: #086D77;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: end;
}
.vertcaltab::before {
  content: "";
  position: absolute;
  border-left: 1px solid #0000001A;
  height: 616px;
  left: 19px;
  top: 20px;
  z-index: 0;
}
.remarkDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remarkDate button {
  width: 120px;
  border-radius: 5px !important;
  height: 40px !important;
  background: #FFEAEA !important;
  border: 0 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #F23A3D !important;
  font-family: 'Poppins' !important;
}
.remarkDate button img {
  width: 16px !important;
  height: 18px !important;
  object-fit: contain;
  position: relative;
  right: 2px;
  bottom: 1px !important;
}
.remarkDescription p {
  margin: 0 !important;
  font-family: 'Poppins';
  color: #1E1E1E;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.remarkDescription {
  border: 1px solid #D8DAE1B2 !important;
  border-radius: 5px !important;
  padding: 10px 14px;
}
.remarkDate h4 {
  color: #1E1E1E;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
textarea.h-auto.textareaBox.form-control::placeholder {
  color: #222943CC !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
.textareaBox {
  border: 1px solid #D8DAE1B2 !important;
  background: #F4F8FB !important;
  margin-bottom: 14px !important;
  color: #222943CC !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.vertcaltab {
  position: relative;
  width: 270px !important;
}
.iframeMap {
  height: 600px !important;
  margin-bottom: 66px;
}
.addInvoice img {
  position: relative;
  right: 4px;
  bottom: 1px;
}
.invoiceTable tbody :nth-of-type(odd)>*{
  background-color: #F8F8F8 !important;
}
.invoiceTable thead th {
  padding: 18px 14px !important;
  color: #5A6474;
  border: 0 !important;
  font-size: 16px;
  text-transform: capitalize;
  font-family: "DM Sans";
  font-weight: 700;
}
.noData h5 {
  color: #041C39;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  margin: 16px;
}
.noData button {
  background: #3AC4F226 !important;
  border: 0 !important;
  width: 192px !important;
  height: 48px !important;
  border-radius: 5px !important;
  color: #3AC4F2 !important;
  font-size: 14px !important;
  font-family: 'Poppins';
  font-weight: 650 !important;
}
.noData button img {
  position: relative;
  right: 6px;
}
.invoiceTable {
  margin: 22px 0;
}
.invoiceTable thead th {
  padding: 20px 14px !important;
}
.titleCard {
  display: flow;
  white-space: nowrap;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.adsBtn {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #DADADA;
}
.adsBtn button {
  border: 1px solid #D8DAE199 !important;
  background: transparent !important;
  border-radius: 5px !important;
  color: #667788 !important;
  font-size: 16px;
  font-weight: 400;
  font-family: "poppins";
  height: 44px;
  display: flex;
  align-items: center;
  width: 178px;
}
.adsBtn button img {
  margin-right: 8px;
}
.adsBtn .active.btn.btn-primary {
  border: 1px solid #3AC4F2 !important;
}
.sideTab {
  display: flex;
  align-items: center;
  width: 100%;
}
.tabButton {
  position: relative;
  padding: 19px 0 !important;
}
.tabsBg {
  padding: 20px 20px 20px 40px;
  box-shadow: 0px 4px 30px 0px #2A59981A;
  border-radius: 10px;
  justify-content: space-between;
}
.nextButton {
  background: #3AC4F2 !important;
  font-family: 'Poppins' !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 5px !important;
  height: 50px !important;
  width: 138px;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.locationInput img {
  position: absolute;
  left: 14px;
  top: 16px;
}
.iframeMap iframe {
  width: 100% !important;
  height: 608px !important;
  margin: 16px 0 14px !important;
}
.prevButton {
  background: #3AC4F226 !important;
  height: 50px !important;
  margin-right: 16px !important;
  width: 138px !important;
  border-radius: 5px !important;
  color: #3AC4F2 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
  text-transform: capitalize !important;
}
.locationInput .form-control {
  padding-left: 42px;
}
h3.title {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  margin-bottom: 16px;
}
.custom-selects option[value=""] {
  color: gray; /* Change this color to your desired one */
}
.timeSlot .MuiStack-root {
  padding: 0 !important;
}
.timeSlot .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root {
  height: 50px !important;
  overflow: hidden;
}
.contentBox .MuiInputLabel-shrink {
  display: none !important;
}
.contentBox label {
  color: #22294399 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding-bottom: 8px ;
}
.previewImg:hover +.previewHover {
  display: block !important;
}
.previewHover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000CC;
  border-radius: 10px;
  display: none;
  cursor: pointer;
}
.previewHover img {
  width: 32px;
  margin: 50px 54px;
}
.previewImg {
  width: 140px !important;
  height: 140px !important;
  border-radius: 10px !important;
}
.contentBox input.form-control::placeholder {
  opacity:  1 !important;
  color: #22294380 !important;
  font-weight: 300 !important;
}
.contentBox input.form-control {
  background: #F4F8FB !important;
  border: 1px solid #D8DAE1B2 !important;
  
  border-radius: 5px !important;
  color: #222943CC !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}
.contentBox input::-webkit-outer-spin-button,
.contentBox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.nameBox {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 20px 0px #2A59980D;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-right: 22px;
}
.installationBox {
  display: flex;
  background: #fff;
  align-items: center;
  box-shadow: 0px 4px 20px 0px #2A59980D;
  border-radius: 5px;
  width: 472px;
  max-width: 472px;
  min-width: 472px;
}
.installationBox h5 h6 {
  margin: 4px !important;
  color: #222943CC !important;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  top: 1px;
}
.billboardButton {
  background: #3AC4F2 !important;
  white-space: nowrap !important;
  width: 277px !important;
  min-width: 277px !important;
  margin-left: 22px !important;
  height: 70px;
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-family: "poppins" !important;
  text-transform: capitalize !important;
}
.countrySelect svg {
  opacity: 0 !important;
}
.countrySelect .MuiSelect-select {
  background-image: url(../src/images/graytbn.png);
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  right: 10px;
}
.countrySelect .MuiInputBase-root.MuiOutlinedInput-root {
  height: 50px !important;
  background: #F4F8FB !important;
}
.countrySelect .MuiInputBase-root.MuiOutlinedInput-root fieldset {
  border-color: #D8DAE1B2 !important;
}
.installationBox h5 {
  display: flex
;
  align-items: center;
  margin: 0;
  color: #222943B2;
  font-size: 20px !important;
  font-family: "poppins";
  font-weight: 500;
  width: 100%;
  justify-content: center;
}
.nameBox h2 {
  display: flex;
  margin: 0;
  font-size: 20px;
  align-items: center;
  color: #222943B2;
  font-family: 'Poppins';
  font-weight: 500;
}
.nameBox h2 h4 {
  color: #3AC4F2;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Poppins';
  margin: 0 6px;
  position: relative;
  top: 1px;
}
.nameBox h2 span {
  font-size: 16px !important;
  color: #22294399 !important;
}
.numberSlot {
  position: absolute;
  right: 10px;
  top: 45px;
  z-index: 99;
  cursor: pointer;
}
/* Firefox */
.contentBox input[type=number] {
  -moz-appearance: textfield;
}
.custom-select__placeholder {
  font-family: 'Poppins' !important;
  opacity:  1 !important;
  color: #22294380 !important;
  font-weight: 300 !important;
}
.buttonSelect {
  background: #3AC4F2 !important;
  border: 1px solid #D8DAE1B2 !important;
  font-family: 'Poppins' !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  height: 45px;
  padding: 0 18.5px !important;
}
.custom-select__multi-value {
  background: #3AC4F2 !important;
  font-family: 'Poppins' !important;
  color: #fff !important;
}
.custom-select__multi-value__label {
  color: #fff !important;
}
.custom-select__control {
  border-radius: 5px !important;
  border-color: #D8DAE1B2 !important;
}
.selectTab .custom-select__input {
  font-family: 'Poppins' !important;
  color: #222943CC !important;
  font-weight: 500 !important;
  margin: 0px !important;
  padding: 7px 0 !important;
}
.custom-select__indicators {
  display: none !important;
}
.custom-select__value-container {
  background: #F4F8FB !important;
  border-radius: 5px !important;
}
.contentBox {
  width: 98%;
  border: 1px solid #D8DAE1CC;
  border-radius: 10px;
  padding: 28px 24px;
  margin-left: 2%;
}
span.MuiTabs-indicator {
  display: none !important;
}
.tabButton.active .sideTab h6 {
  font-weight: 600 !important;
}
.tabButton.active .sideTab h4 {
  font-weight: 600 !important;
}
.tabButton.active .sideTab span {
  background: #086D77 !important;
  color: #fff !important;
  border: 1px solid #0039CE;
}
.sideTab span {
  width: 39px;
  min-width: 39px;
  height: 39px;
  border: 1px solid #263238;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 16px;
  color: #263238;
  font-weight: 600;
  font-family: 'Poppins';
  margin-right: 14px;
}
.sideTab h6 {
  color: #7d8488;
  font-size: 16px;
  font-family: 'Poppins';
  text-transform: capitalize;
  font-weight: 400;
  text-align: left;
  margin: 0;
}
.sideTab h4 {
  color: #1E1E1E;
  font-size: 16px;
  font-family: 'Poppins';
  text-transform: capitalize;
  font-weight: 400;
  margin: 0;
  text-align: left !important;
}
.tabsBg form {
  width: 100% !important;
}
@font-face {
  font-family: "DM Sans Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/DMSans-Italic.woff") format("woff");
}

@font-face {
  font-family: "DM Sans Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/DMSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "DM Sans Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/DMSans-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "DM Sans Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/DMSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "DM Sans Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/DMSans-BoldItalic.woff") format("woff");
}

body {
  color: rgba(34, 41, 67, 0.8);
  font-size: 16px;
  font-weight: 500;
  background: #e5e5e5;
  font-family: "Poppins", sans-serif !important;
}
.searchInputs img {
  position: absolute;
  top: 16px;
  left: 10px;
}
.searchInputs input {
  padding-left: 42px !important;
  font-family: "poppins";
  color: #000;
}
.filterBtn {
  width: 95px !important;
  height: 50px !important;
  border: 1px solid #D8DAE1CC !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  color: #00000099 !important;
  font-family: "poppins" !important;
  font-weight: 400 !important;
}
.previewFullImage {
  text-align: center;
position: relative;
}
body .actionBtn img {
  width: 24px;
  height: 24px !important;
  object-fit: contain;
  cursor: pointer;
  margin: 10px !important;
}
.actionBtn {
  text-align: end;
  right: 0;
  top: 10px;
}
.previewFullImage img {
  margin: 20px;
  height: 900px ;
}
.operationGallery {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}
.galleryImg img {
  width: 342px;
  height: 342px;
  margin: 14px 0;
  border-radius: 10px;
}
.hoverDetail ul {
  padding: 0 !important;
  margin: 0 !important;
}
.hoverDetail ul li {
  list-style: none !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 6px 14px;
}
.hoverDetail ul li span {
  font-weight: 700;
  font-family: 'Poppins';
  width: 144px;
  display: inline-flex;
}
.galleryImg:hover .hoverDetail {
  display: block !important;
}
.hoverDetail {
  display: none;
}
.hoverDetail {
  background: #000000ab;
  position: absolute;
  bottom: 16px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.galleryImg {
  position: relative;
}
.filterBtn img {
  position: relative;
  right: 7px;
}
.searchInputs input::placeholder {
  color: #C3C7CE !important;
  opacity: 1 !important;
}
.searchInputs .MuiFormControl-root.MuiTextField-root {
  width: 367px;
  min-width: 367px
}
.searchInputs .MuiInputBase-root.MuiOutlinedInput-root {
  height: 50px !important;
}
.searchInputs fieldset {
  border: 1px solid #D8DAE1CC !important;
  border-radius: 10px !important;
}
.searchInputs {
  position: relative;
  width: 367px;
}
.catalogueCard {
  display: flex;
  align-items: center;
  border: 1px solid #D8DAE1CC;
  border-radius: 10px;
  padding: 12px 4px 12px 12px;
}
.catalogueCard img {
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.catalogueCard ul {
  padding: 0 0 0 18px;
  border-left: 1px solid #CECECE;
  margin: 0 0 0 8px;
}
.catalogueCard ul li {
  list-style: none;
  font-size: 12px;
  color: #00000099;
  font-weight: 400;
  padding-bottom: 8px;
}
.catalogueCard ul li span {
  font-weight: 500 !important;
  color: #212121 !important;
  display: inline-flex
;
  width: 120px;
}
.countryDiv div {
  text-align: left !important;
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root,
.css-10hburv-MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}
.Text-eslips {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
img {
  max-width: 100%;
}

.btn-dropmenu {
  padding: 5px 8px !important;
  background: #f6f6f6 !important;
  border-radius: 5px !important;
  color: #0a2a48 !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  width: 178px;
  height: 50px;
  font-family: "Poppins", sans-serif !important;
}

.navLink {
  background: rgba(58, 196, 242, 0.1);
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 15px;
  color: #3ac4f2;
  font-size: 16px;
  width: 128px;
  height: 50px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: relative;
}

.navLink:hover {
  background: #3ac4f2;
  color: #fff;
}

.card-content {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgb(42 89 152 / 10%) !important;
  border-radius: 10px !important;
}
.toolbar {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.heading-title h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #222943;
  margin: 0px;
}
.user-pic-cricle {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: inline-block;
  position: relative;
}

.user-pic-cricle img {
  border-radius: 100%;
  width: 150px;
  height: 150px;
  border: 8px solid #f9fafe;
}

.text-gray {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(34, 41, 67, 0.5);
  margin: 0px;
}
.edit-icon {
  position: absolute !important;
  bottom: 13px;
  right: 0;
  background: #3ac4f2 !important;
  border: 3px solid #fff !important;
  color: #fff !important;
}
.bg-gray {
  background: #f6f7fc;
  border-radius: 5px;
  padding: 15px;
}
.text-green {
  color: #7de288;
}
.text-red {
  color: #ec4c4c;
}

.table-head-bg th {
  color: #5a6474 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border: 0px !important;
  text-transform: uppercase !important;
  font-family: "DM Sans" !important;
  font-style: normal;
  line-height: 20px;
}

.tablebody-first th {
  color: #5a6474 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
  white-space: nowrap;
  border: 0px !important;
}

b.titletable {
  font-size: 14px;
  font-weight: 500;
}

.addiconbox {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addtext {
  color: #757d8a;
  font-size: 16px;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
}
.tablebody-first tr:nth-child(odd) {
  background: #f8f8f8 !important;
}
.titletable a {
  color: #5a6474;
  text-decoration: none;
}
.bilboard-text {
  text-decoration: none;
  color: #5a6474;
}
.heading-title p {
  font-weight: 400;
  font-size: 16px;
  color: rgba(34, 41, 67, 0.6);
  margin: 0px;
}
.input-control {
  background: #f4f8fb;
  border: 1px solid rgba(216, 218, 225, 0.7);
  border-radius: 5px;
}
.role-radio .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 7px;
}
.add-btn {
  background: #ffffff !important;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px !important;
  padding: 10px !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3ac4f2 !important;
  text-transform: capitalize !important;
}

.addicon {
  background: rgba(58, 196, 242, 0.1);
  border-radius: 34px;
  display: inline-flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #3ac4f2;
  margin-right: 10px;
}

.card-content2 {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.bilboard-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #086d77;
  background: rgba(8, 109, 119, 0.1);
  border-radius: 3px;
  padding: 5px 10px;
}
.download-icon svg {
  fill: #3ac4f2;
  font-size: 30px;
}
.download-icon {
  text-decoration: none;
}
.bilboard-content img {
  border-radius: 7px;
}
.text-clock {
  color: rgba(34, 41, 67, 0.7);
  font-size: 9px;
}
.text-clock span {
  margin-left: 5px;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.progressBar-1 .MuiLinearProgress-barColorPrimary {
  background: #086d77 !important;
}
.card-header {
  background: #e9ebf7;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(34, 41, 67, 0.8);
}

.progressBar-1.css-f6opzo-MuiLinearProgress-root {
  background: rgba(8, 109, 119, 0.1) !important;
}

.progressBar-2 .MuiLinearProgress-barColorPrimary {
  background: #9f03ff !important;
}

.progressBar-2.css-f6opzo-MuiLinearProgress-root {
  background: rgba(8, 109, 119, 0.1) !important;
}

.progressBar-3 .MuiLinearProgress-barColorPrimary {
  background: #f99e3f !important;
}

.progressBar-3.css-f6opzo-MuiLinearProgress-root {
  background: rgba(249, 158, 63, 0.1) !important;
}
.progressBar-4 .MuiLinearProgress-barColorPrimary {
  background: #3ac4f2 !important;
}

.progressBar-4.css-f6opzo-MuiLinearProgress-root {
  background: rgba(58, 196, 242, 0.1) !important;
}
.progressBar-5 .MuiLinearProgress-barColorPrimary {
  background: #f23a92 !important;
}

.progressBar-5.css-f6opzo-MuiLinearProgress-root {
  background: rgba(242, 58, 146, 0.15) !important;
}
.progressBar-6 .MuiLinearProgress-barColorPrimary {
  background: #00bc8b !important;
}

.progressBar-6.css-f6opzo-MuiLinearProgress-root {
  background: rgba(0, 188, 139, 0.1) !important;
}
.card-content-3 {
  background: #f6f7fc !important;
  border-radius: 8px !important;
  overflow: hidden;
}
.title-report {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: rgba(34, 41, 67, 0.6);
  padding-bottom: 10px;
}

.listitemsbox .css-cveggr-MuiListItemIcon-root {
  min-width: 42px;
}

.listitemsbox
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  color: #3ac4f2;
}

.listitemsbox
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected
  .MuiListItemIcon-root {
  color: #3ac4f2;
}

.listitemsbox
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root
  .css-10hburv-MuiTypography-root {
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins";
}
.listitemsbox .MuiButtonBase-root.MuiListItemButton-root {
  padding: 5px;
}
.labelslect {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #001d38;
}
.selectdiv-list {
  font-family: "Poppins" !important;

  font-weight: 400 !important;
  font-size: 16px !important;
  color: #001d3899 !important;
}

.droppop-slect span {
  font-family: "Poppins" !important;

  font-weight: 500 !important;
  font-size: 14px !important;

  color: rgba(34, 41, 67, 0.6) !important;
}

.Mui-selected.droppop-slect {
  color: #3ac4f2;
  background: rgba(58, 196, 242, 0.1) !important;
}
.form-control-sender {
  background: #f4f8fb !important;
  border: 1px solid rgba(216, 218, 225, 0.5) !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
}
.form-control-sender.form-control {
  background: #f4f8fb !important;
  border: 1px solid rgba(216, 218, 225, 0.5) !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
}

.eyeicon {
  position: absolute;

  right: 11px;
  top: 6px;
  color: rgba(34, 41, 67, 0.4);
}
.navLink.header-active {
  background: #3ac4f2;
  color: #fff;
}
.datetime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(34, 41, 67, 0.8);
}

.datetime b {
  font-weight: 500;
}
.home-tabs {
  background: #c4c5cb !important;
  border-radius: 4px !important;
  font-family: "Poppins !important";

  font-weight: 400 !important;
  font-size: 36px !important;
  height: 60px;
  text-align: center !important;
  text-transform: capitalize !important;
  color: rgba(34, 41, 67, 0.7) !important;
}
.home-tabs:hover {
  background: #ddf2fc !important;
  color: #3ac4f2 !important;
}
.home-tabs.home-tabs-active {
  background: #ddf2fc !important;
  color: #3ac4f2 !important;
  font-weight: 700 !important;
}

.home-title {
  line-height: 40px;
  color: #404d61;
  font-weight: 700;
  font-family: "DM Sans";
  font-size: 32px;
}
.card-content-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  color: #222943;
}
.box-color {
  background: #086d77;
  border-radius: 8px;
}
.iconchart {
  background: #ffffff;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100%;
}
.timebox h4 {
  font-weight: 600;
  font-size: 40px;

  color: #ffffff;
  margin: 0;
}
.timebox-1 h4 {
  font-weight: 600;
  font-size: 24px;

  color: #ffffff;
  margin: 0;
}

.timebox span {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 400;
}

.timebox-1 span {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 400;
}
.logs-calendar-date .css-xelq0e-MuiPickerStaticWrapper-content {
  background: transparent;
}

.logs-calendar-date button {
  background: transparent;
  font-size: 18px !important;
  color: #5a6474;
  font-weight: 600;
}
.logs-calendar-date {
  color: #404d61 !important;
  margin-left: -16px;
}
.logs-calendar-date .css-epd502 {
  max-height: 315px !important;
}
.logs-calendar-date .css-1cnkspq {
  min-height: 203px !important;
}
.logs-calendar-date .css-1dozdou {
  color: #404d61 !important;
  font-family: "Poppins !important";
  font-size: 18px !important;
}

.logs-calendar-date .css-1dozdou .css-1v994a0 {
  color: #404d61;
  font-family: "Poppins";
  font-size: 18px;
}

.css-1dozdou {
  padding: 0px;
}

.logs-calendar-date .css-1w13o7u-MuiTypography-root {
  color: #757d8a;
  font-size: 18px;
}

.fullcalendar .fc-button-group button,
.fullcalendar .fc-button-group button:focus {
  background: transparent;
  color: #757d8a;
  border: 0;
  font-weight: 400;
  font-family: "Poppins";
  border-bottom: 2px solid transparent;
  border-radius: 0px !important;
  outline: none !important;
  box-shadow: none !important;
  text-transform: capitalize;
}

.fullcalendar .fc-button-active {
  background: transparent !important;
  color: #4e73f8 !important;
  border-bottom: 2px solid #4e73f8 !important;
}
.fullcalendar a {
  text-decoration: none;
  color: #757d8a;
}

.bg-donut-chart {
  background: #f8f9fd;
  border-radius: 5px;
  padding: 10px 15px;
}

.donut-chart-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222943;
  opacity: 0.8;
}
.chart-series {
  border-radius: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.donut-chart-percent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(34 41 67 / 60%);
}
.print_btn {
  background: #f6f6f6 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: rgba(10, 42, 72, 0.6) !important;
  min-width: 44px !important;
  height: 44px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.fillter-day {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(34, 41, 67, 0.8);
}
.details-box {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: rgba(34, 41, 67, 0.7);
}

.details-box b {
  color: #3ac4f2;

  font-size: 18px;

  font-weight: 600;
}
.details-box big {
  font-weight: 600;
  font-size: 16px;
}
.billboard-btn {
  background: #3ac4f2;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 18px 11px;
  white-space: nowrap;
  text-align: center;
  outline: none;
  border: none;
}
.billboard-btn:hover {
  background: #54cef7;
  color: #fff;
}
.over-scroll {
  overflow: hidden;
  overflow-y: auto;
  min-height: 360px;
  max-height: 360px;
}

.over-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(58, 196, 242, 0.1);
}

.over-scroll::-webkit-scrollbar {
  width: 5px;
  background: rgba(58, 196, 242, 0.1);
}

.over-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3ac4f2;
  box-shadow: 0px 4px 30px rgba(4, 19, 97, 0.03);
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(58, 196, 242, 0.1);
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar {
  width: 5px;
  background: rgba(58, 196, 242, 0.1);
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #086d77;
  box-shadow: 0px 4px 30px rgba(4, 19, 97, 0.03);
}
.advertising-box {
  background: #086d77;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.advertising-count {
  width: 50%;
  background: #f4f8fb;
  border-radius: 0px 5px 5px 0px;
  margin: 0px !important;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}
.offline-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: inherit;
  letter-spacing: 0.02em;
  color: rgb(0 29 56 / 60%);
}

.offline-box h6 {
  color: rgb(34 41 67 / 80%);
  font-size: 18px;
}
.auto-height {
  height: 320px;
}
.bg-green {
  background: #086d77;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.w-138 {
  width: 138px;
  height: 50px;
}

.bg-gray label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #001d38;
  opacity: 0.8;
}

.form-control {
  border: 1px solid rgba(216, 218, 225, 0.6);
  border-radius: 5px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #001d38 !important;

  background: #fff !important;
}

.form-control::-webkit-input-placeholder {
  color: rgba(34, 41, 67, 0.3) !important;
  font-weight: 500;
  font-size: 16px;
}
.form-control:-moz-placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #001d38;

  opacity: 0.6;
} /* firefox 19+ */
.form-control:-ms-input-placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #001d38;

  opacity: 0.6;
} /* ie */
.form-control:-moz-placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #001d38;

  opacity: 0.6;
}
.statistics-tabs {
  background: rgba(58, 196, 242, 0.15) !important;
  border-radius: 4px !important;
  height: 60px;
  text-transform: capitalize !important;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;

  text-align: center;
  color: #3ac4f2 !important;
}
.statistics-tabs:hover {
  color: #fff !important;
  background: #3ac4f2 !important;
}

.statistics-tabs.statistics-tabs-active {
  color: #fff !important;
  background: #3ac4f2 !important;
}
.fillter-day-select {
  color: rgba(34, 41, 67, 0.6);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.w-200 {
  width: 200px;
  height: 44px;
}

.w-128 {
  width: 128px;
  height: 44px;
}

textarea.form-control {
  min-height: 340px;
}
.form-control-sender1 {
  border-radius: 0px;
  background: #f4f8fb !important;
}
.form-select {
  border: 1px solid rgba(216, 218, 225, 0.6);
  border-radius: 5px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #001d38 !important;
  /* background: #fff !important; */
}
.space-event {
  border: 0;
  border-left: 4px !important;
  border-radius: 5px !important;
  padding-left: 5px;
  overflow: hidden;
}

.billboard-log.fc-event {
  background-color: #fff8f1;
  border-radius: 5px !important;
  padding-left: 5px !important;
}

.billboard-log.fc-event .event-content {
  color: #fca549;
}

.billboard-offline.fc-event {
  background-color: #fb470a;
  border-radius: 5px !important;
  padding-left: 5px !important;
  border: none;
}
.billboard-offline.fc-event .event-content {
  color: #fff;
}

.alert-log.fc-event {
  background-color: #bf0404;
  border-radius: 5px !important;
  padding-left: 5px !important;
  border: none;
}

.alert-log.fc-event:hover {
  background-color: #e91616;
}
.alert-log.fc-event .event-content {
  color: #fff;
}

.login-log.fc-event {
  background-color: #ebf8f6;
  padding-left: 5px !important;
  border: none;
}
.login-log.fc-event .event-content {
  color: #07a287;
}

.report-log.fc-event {
  background-color: #617ff4;
  padding-left: 5px !important;
  border: none;
}
.report-log.fc-event .event-content {
  color: #fff;
}

/* STYLING FOR MAP MARKER */

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}

.marker:hover {
  z-index: 1;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
  display: none;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.destop-hide {
  display: none;
}
@media (min-width: 992px) {
  .long-height {
    display: none;
  }
}

@media (max-width: 992px) {
  .long-height {
    display: block;
  }
  .pickerlist em-emoji-picker {
    width: 95% !important;
    margin: 0 auto !important;
    position: relative;
    left: 13px;
}
.scroll.flex-grow.padding-lr {
  padding: 0 !important;
}
  .simplebar-track.simplebar-horizontal {
    display: none;
  }
  .simplebar-scrollbar::before {
    background-color: transparent;
  }
  .textbill {
    margin-top: 10px;
  }
  .responsivecchart {
    width: 1000px;
  }
  .overflow-m {
    overflow: hidden;
    overflow-x: auto;
  }

  .destop-hide {
    display: block;
  }

  .destop-hide {
    display: block;
  }
  .mobile-none {
    display: none !important;
  }
  .btn-dropmenu {
    width: auto !important;
    height: auto !important;
    background: transparent !important;
    padding: 0px !important;
    margin-right: 0px !important;
    min-width: auto !important;
    letter-spacing: 0px !important;
  }
  .mobile-size {
    width: 150px;
  }
  .mobile-menu {
    background: rgba(58, 196, 242, 0.1);
    border: 1px solid #3ac4f2;
    border-radius: 4px;
    margin: 16px -6px;
  }

  .mobile-menu a {
    background: rgba(58, 196, 242, 0.1);
    text-decoration: none;
    padding: 10px 5px;
    color: #3ac4f2;
    font-size: 11px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    display: block;
    width: 100%;
    position: relative;
  }
  .mobile-menu a.header-active {
    background: #3ac4f2;
    color: #fff;
  }
  .statistics-tabs {
    height: auto !important;
    font-size: 14px !important;
  }
  .timebox h4 {
    font-size: 24px;
  }
  .timebox-1 h4 {
    font-size: 12px;
  }
}
.scrollvbar {
  overflow-x: auto;
  max-width: 205px;
}

.pdf-cont {
  font-size: 30px;
}
.map {
  height: 325px;
}
.bilboard-title {
  overflow-x: auto;
}
.bilboard-title::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.bilboard-title::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.bilboard-title::-webkit-scrollbar-thumb {
  background-color: #086d77;
}
.timebox h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* max-width: 90px; */
}

@media (max-width: 992px) {
  .advertising-count {
    font-size: 13px;
  }
  .fillter-day {
    font-size: 12px;
  }
  .advertising-box {
    font-size: 12px;
  }
  .mobilecard-block {
    display: block;
  }
  .mobilecard-none {
    display: none;
  }
  .pdf-container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .mobileview-icon {
    float: left;
  }

  .mobileview-time {
    overflow: hidden;
    font-size: 20px;
  }

  .card-content-title {
    font-size: 16px;
  }
}

.mobule-bill {
  min-width: 175px;
}
.scrollview {
  overflow: auto;
}

.fc .fc-more-popover .fc-popover-body {
  overflow: auto;
  max-height: 400px;
}
@media (min-width: 1536px) {
  .viewMap {
    height: calc(100vh - 305px) !important;
  }
}

.droplist {
  color: rgb(0 0 0 / 57%);
  margin-right: 8px;
  position: absolute;
  left: 6px;
}
.fillterby {
  padding-left: 15px;
  border-bottom: 1px solid #cccccc5e;
  padding-bottom: 5px;
}
.fixed-create {
  position: fixed;
  bottom: 50px;
  left: -16px;
  z-index: 110000;
}
.maxLimit {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  padding-right: 10px;
}

.long-height svg {
  color: rgba(0, 0, 0, 0.54);
}
@media (max-width: 480px) {
  .show-mobileview {
    position: absolute;
    bottom: 52px;
    height: 300px !important;
    left: 15px;
    right: 9px;
    width: auto !important;
    box-shadow: rgb(145 158 171 / 56%) 0px -5px 16px 0px;
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.count {
  width: 20px;
  height: 20px;
  display: inline-flex;
  color: #0a07ed;
  text-decoration: underline;
  /* border-radius: 100%; */
  /* background: #086d77; */
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.post-link {
  color: green;
}
.text-limit {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardbox-blog {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 5px;
  box-sizing: border-box;
}
.datecolor {
  color: rgba(0, 0, 0, 0.6);
}
.count-header {
  width: 20px;
  height: 20px;
  display: inline-flex;
  color: #fff;
  border-radius: 100%;
  background: #d32f2f;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  right: -4px;
}
.arrowicon svg {
  font-size: 40px;
}
ul.submenu {
  margin: 0px;
  padding: 0 0px 0px 15px;
  font-size: 14px;
}
ul.submenu li a:before {
  display: none;
}

.ql-editor {
  display: block;
}
.raw-editor {
  display: none;
  border: 1px solid #ccc;
  width: 100%;
  padding: 5px;
  resize: none;
  border-top: 0px;
  outline: none;
  height: 250px;
}
.showRaw .ql-editor {
  display: none;
}
.showRaw .raw-editor {
  display: block;
}

.ql-container.ql-snow {
  border-top: 0px;
}

td.fc-daygrid-day.fc-day {
  overflow: hidden;
}

#mui-component-select-billboard {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.form-control-sender.form-control.border-raius-field {
  border-radius: 0px 5px 5px 0px !important;
}
.input-date-defult {
  background: #f4f8fb;
  border: 1px solid rgba(216, 218, 225, 0.7);
  border-radius: 0px 5px 5px 0px;
  width: 100%;
  height: 36px;
  padding: 0px 10px;
  border-left: 0px;
  outline: none;
}

.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: 100px;
}

.k-pdf-export {
  position: relative;
}
.k-pdf-export .bottomfixed {
  margin-top: 550px;
}
.k-pdf-export .noExport {
  display: none;
}
.input-search {
  position: relative;
}

.input-search span {
  position: absolute;
  top: 0;
  right: 14px;
  transform: translateY(13px);
  color: #9daab6;
  cursor: pointer;
}
textarea.input-textarea {
  resize: none;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #f3f3f3;
  background: #fff;
  outline: none;
  height: 150px;
  margin-bottom: 16px;
}
.tp-btn-blue {
  background: #3ac4f2;
  border-radius: 5px;
  color: #fff;
  line-height: 27px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  outline: none;
  border: 0;
}
.tp-btn-blue:hover {
  background: #51cef8;
  color: #fff;
  border-color: #51cef8;
}
.rating-billboards {
  border-radius: 8px 0px 0px 8px;
  background: #086d77;
  padding: 8px;
}

.rating-billboards span {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}
.gray-bg {
  border-radius: 0px 8px 8px 0px;
  background: #f4f8fb;
  padding: 8px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: rgba(34, 41, 67, 0.8);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
}

.custom-datepiker .rdrDefinedRangesWrapper {
  display: none;
}
.h-40 {
  height: 42px;
}
.custom-icon input::-webkit-inner-spin-button,
.custom-icon input::-webkit-clear-button {
  display: none;
}

.custom-icon input::-webkit-calendar-picker-indicator {
  opacity: 0.5;
  position: absolute;
  left: 12px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.custom-icon input::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}
.custom-icon .MuiInputBase-root {
  padding-left: 35px;
}
.scrollview {
  overflow: hidden;
  overflow-y: auto;
  max-height: 225px;
  padding-right: 5px;
}
.scrollview::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollview::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollview::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3ac4f2;
}
.dclass a {
  white-space: normal !important;
}
.partner h2:first-child {
  border-top: 0px;
}

.usermer caption {
  border: 1px solid #dee2e6;
  border-bottom: 0px;
  padding-left: 10px;
}
.listviewbox {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
ul.listviewbox li {
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.partner table th {
  font-size: 14px;
  white-space: nowrap;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;

  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0px;
  right: 0;
  position: absolute;
  bottom: 0px;
  top: 0px;
  width: auto;
  bottom: 0;
}
.react-tel-input .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #d5d5d5;
}
.react-tel-input .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #d5d5d5;
}
.react-tel-input .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #d5d5d5;
}
.react-tel-input .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #d5d5d5;
}
/* new csss */
.bg-color-billboard {
  background: #f9fafe;
}

[disabled] {
  cursor: not-allowed;
}

[disabled] * {
  pointer-events: none;
}

[role="dialog"] {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.assign-list ul {
  margin: 0px;
  padding: 0px;
}
.swal2-backdrop-show {
  z-index: 9999;
}
